import { css } from "@emotion/react";
import "@fontsource/roboto-mono";
import "@fontsource/roboto-mono/500.css";
import "@fontsource/new-rocker";

const typography = css`
  html {
    font-size: 10px;
  }

  body {
    font-size: 1.6rem;
    letter-spacing: 0.8px;
    font-family: "Roboto Mono";
  }

  h1 {
    font-family: "New Rocker";
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.4rem;
    color: #ba9820;

    @media only screen and (min-width: 600px) {
      font-size: 3.8rem;
    }
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 500;

    @media only screen and (min-width: 600px) {
      font-size: 2.4rem;
    }
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
    
    @media only screen and (min-width: 600px) {
      font-size: 1.8rem;
    }
  }
  
  p {
    margin: 1.8rem;

    @media only screen and (min-width: 600px) {
      margin: 3.2rem;
  }
`;

export default typography;
