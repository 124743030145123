import { useState } from "react";
import styled from "@emotion/styled";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccordionItem = ({ children, title }) => {
  const [show, setShow] = useState(false);

  return (
    <ItemWrapper>
      <ItemHeader onClick={() => setShow(!show)}>
        <h3>{title}</h3>
        <FontAwesomeIcon icon={show ? faMinus : faPlus} color="#ba971f" />
      </ItemHeader>
      {show && <ItemContent>{children}</ItemContent>}
    </ItemWrapper>
  );
};

const Accordion = ({ children }) => {
  return (
    <Wrapper>
      {children && children.length >= 1 && children.map((item) => item)}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const ItemWrapper = styled.div`
  padding: 2.4rem 0.8rem;
  border-bottom: 1px solid #252039;
`;

const ItemHeader = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemContent = styled.p`
  margin-bottom: 0;
`;

export { Accordion, AccordionItem };
