import styled from "@emotion/styled";
import Countdown from "react-countdown";

const TimeCountdown = ({ timestamp }) => {
  return (
    <Wrapper>
      <Countdown date={timestamp}>
        <LinkToAuction>
          Tomes are usually auctioned once a week!
          <br />
          <a
            href="https://twitter.com/FRWCSpells"
            target="_blank"
            rel="noreferrer"
          >
            Follow @FRWCSpells{" "}
          </a>{" "}
          on twitter to know when a new one is available!
        </LinkToAuction>
      </Countdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: "New Rocker";
  font-size: 4.2rem;
  color: #361a6b;
  margin-top: 40%;

  @media only screen and (min-width: 800px) {
    font-size: 12.4rem;
    margin-top: 0;
  }
`;

const LinkToAuction = styled.div`
  // font-family: "Roboto Mono";
  text-align: center;
  padding: 0 6.4rem 6.4rem;
  font-size: 2.4rem;
  color: #fefde1;
  a {
    color: #ba9820;
  }
`;

export default TimeCountdown;
