import React from "react";
// import { useConnect, useAccount } from "wagmi";
import styled from "@emotion/styled";
// import AccountBadge from "../AccountBadge";
// import ConnectButton from "../ConnectButton";
import Container from "../Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import OSIcon from "../../assets/opensea.svg";

const Header = () => {
  // const [{ data: connectorData, error: ConnectorError }, connect] = useConnect();
  // const [{ data: accountData }, disconnect] = useAccount({
  //   fetchEns: true,
  // });

  return (
    // <StyckyTop>
    <Absolute>
      <Container>
        <StyledHeader>
          <h1>Forgotten Spells</h1>
          <Socials>
            <a
              href="https://twitter.com/FRWCSpells"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} color="#ba9820" />
            </a>
            <a
              href="https://opensea.io/collection/forgottentomes"
              target="_blank"
              rel="noreferrer"
            >
              <img src={OSIcon} alt="OpenSea" />
            </a>
          </Socials>
        </StyledHeader>
      </Container>
      {/* <AccountContainer>
          {accountData ? (
            <AccountBadge account={accountData} onDisconnect={disconnect} />
          ) : (
            <ConnectButton
              data={connectorData}
              error={ConnectorError}
              onConnect={connect}
            />
          )}
        </AccountContainer> */}
    </Absolute>
    // </StyckyTop>
  );
};

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2.4rem;

  @media only screen and (min-width: 600px) {
    padding: 4.8rem;
    align-items: center;
    flex-direction: column;
    align-items: flex-end;
  }
`;

// const AccountContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;

const Socials = styled.div`
  padding-right: 0.8rem;

  a {
    img {
      padding-top: 2rem;
    }
  }

  @media only screen and (min-width: 700px) {
    margin-top: 4.2rem;
  }
`;

export default Header;
