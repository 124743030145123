import Container from "../../components/Container";
import styled from "@emotion/styled";
import sky from "../../assets/sky.gif";
import tome from "../../assets/tome.gif";
import cosmo from "../../assets/wizard.gif";
import tad from "../../assets/55.gif";
import FAQ from "../../components/FAQ";
import TimeCountdown from "../../components/TimeCountdown";

const Home = () => {
  return (
    <HomeWrapper>
      <TopSection>
        <CTA>
          <TimeCountdown timestamp={1650484800000} />
        </CTA>
      </TopSection>
      <SectionWrapper bg="#00061c" color="#fefde1">
        <Container>
          <MidSection>
            <div>
              <h2>1. Acquire a Forgotten Tome</h2>
              <p>
                A series of mysterious tomes, capable of tapping into a wizard's
                deepest powers, have surfaced.
              </p>
              <p>
                The Tomes are not easy to come by, however. Only one will be
                available for auction each week.
              </p>
            </div>
            <Gif width="400px">
              <img src={tome} alt="Forgotten Tome" />
            </Gif>
          </MidSection>
        </Container>
      </SectionWrapper>
      <SectionWrapper bg="#00061c" color="#fefde1">
        <Container>
          <MidSection>
            <Gif glow="#c32454" width="350px">
              <img src={cosmo} alt="Fire Spell" />
            </Gif>
            <div>
              <h2>2. Learn a Forgotten Spell</h2>
              <p>
                Legend has it that wizards who get their hands on a Tome are
                able to unleash a magic not seen in a long time.
              </p>
              <p>Each Forgotten Tome can teach a Forgotten Spell.</p>
              <p>
                Each spell will be animated and 100% custom-made to highlight
                the powers of the wizard learning it.
              </p>
            </div>
          </MidSection>
        </Container>
      </SectionWrapper>
      <SectionWrapper bg="#00061c" color="#fefde1">
        <Container>
          <MidSection padding="5em">
            <div>
              <h2>3. Vote on the Lore</h2>
              <p>
                As the project matures, more and more lore/art will be created
                for the overarching plot. Wizards who learn Forgotten Spells
                will have an input into the creation of the collective Forgotten
                Spells storyline.
              </p>
              <p>Help answer questions like "Who is creating these tomes?"</p>
            </div>
            <Gif glow="#b7dbf3" width="350px">
              <img src={tad} alt="Forgotten Tome" />
            </Gif>
          </MidSection>
        </Container>
      </SectionWrapper>
      <SectionWrapper bg="#040e04" color="#fefde1">
        <Container>
          <FAQ />
        </Container>
      </SectionWrapper>
    </HomeWrapper>
  );
};

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 5em;
`;

const TopSection = styled.div`
  height: 100%;
  min-height: 35vh;
  background-image: url(${sky});
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 700px) {
    min-height: 100vh;
  }
`;

const CTA = styled.div``;

const MidSection = styled.div`
  display: grid;
  grid-gap: 1.8rem;
  align-items: center;
  justify-items: center;
  padding-bottom: ${(p) => (p.padding ? p.padding : undefined)};

  @media only screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4.8rem;
  }
`;

const Gif = styled.div`
  order: 2;

  img {
    border-radius: 3.2rem;
    width: 300px;
    height: auto;
    box-shadow: 0 0 15px ${(p) => (p.glow ? p.glow : "transparent")};
  }

  @media only screen and (min-width: 600px) {
    order: initial;
    img {
      width: ${(p) => p.width};
    }
  }
`;

const SectionWrapper = styled.div`
  background: ${(p) => (p.bg ? p.bg : "transparent")};
  color: ${(p) => (p.color ? p.color : "black")};
  padding: 3em 1em;

  @media only screen and (min-width: 600px) {
    padding: 3em;
  }
`;

export default Home;
