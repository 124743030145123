import { css } from "@emotion/react";

const layout = css`
  html,
  body {
    margin: 0;
    padding: 0;
    background: #01091e; 
    color: #fefde1;
  }

  #root {
    height: 100%;
  }
`;

export default layout;
