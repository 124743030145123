import styled from "@emotion/styled";
import { Accordion, AccordionItem } from "../Accordion";

const FAQ = () => {
  return (
    <Wrapper>
      <h1>Frequently Asked Questions</h1>
      <Accordion>
        <AccordionItem title="Is there a Discord?">
          No, and there won't be. We already have an amazing discord for the
          cult, so I think that is the best place to catch up.
        </AccordionItem>
        <AccordionItem title="Is there an allow list?">
          No. Only one Forgotten Tome will be auctioned per week, which will be
          open to anyone.
        </AccordionItem>
        <AccordionItem title="Do I need to own a Wizard?">
          You don't have to own a wizard to purchase a Forgotten Tome. However
          all Forgotten Spells will be created for Wizards, Souls, Beasts &
          Warriors. While I have ambitions of future utility for the Forgotten
          Tomes themselves, there are no promises and the only current purpose
          of a Tome is learning a Forgotten Spell.
        </AccordionItem>
        <AccordionItem title="What if I only have a baby?">
          I really encourage using a Tome for your FRWC token. However, if you
          really want to teach a Forgotten Spell to a baby, you may do so - at
          your own risk. Most babies are not yet strong enough to yield such
          power, and the results may be unpredictable.
        </AccordionItem>
        <AccordionItem title="What is the starting price?">
          The auction for the first Forgotten Tome will start at 0.25Ξ. Every 4
          weeks after the starting price will raise by 0.05Ξ
        </AccordionItem>
        <AccordionItem title="How long will it take to get my custom spell?">
          Once a tome is used to redeem a spell, it'll take 3-7 days for your to
          receive your new NFT.
        </AccordionItem>
        <AccordionItem title="Is there an expiration date on my Forgotten Tome?">
          No, but keep in mind that if you don't use it with in the first week
          you will go to the back of the line for receiving your NFT as new
          owners redeem.
        </AccordionItem>
        <AccordionItem title="What are you doing with all that ETH?">
          The funds from each auction will be distributed 3 ways:
          <br />
          <br />
          - 20% will be donated to the Forgotten Council.
          <br />
          <br />
          - 50% will be given to the creator.
          <br />
          <br />- 30% will remain in the project treasury and will be used to
          acquire wizards, lore and art for furthering development of the
          Forgotten Spells storyline.
        </AccordionItem>
        <AccordionItem title="Are there royalties for secondary?">
          The Forgotten Tomes will have a 5% royalty fee.
          <br />
          The Forgotten Spells will be royalty free.
        </AccordionItem>
        <AccordionItem title="What is the utility of a Forgotten Tome?">
          The immediate utility of your Forgotten Tome is to teach 1 wizard,
          soul, warrior, beast or baby a spell. Each tome can only redeem one
          spell.
        </AccordionItem>
        <AccordionItem title="What is the utility of a Forgotten Spell?">
          The Forgotten Spell will be a custom, animated piece of art issued as
          a ERC721 Token. Aside from having input on the Forgotten Spells
          storyline, there is no other utility planned for now.
        </AccordionItem>
      </Accordion>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4.8rem 0;

  h1 {
    margin-bottom: 2.4rem;
    width: 100%;
  }
`;

export default FAQ;
